import React from "react"
import styled from "styled-components"
import { RefreshIcon } from "./icons"

const NotesView = (props) => {
  const redo = () => {
    props.callback()
  }
  return (
    <NotesViewContainer>
      <NotesTextCont aria-live="polite" id="notes-view">
        <NotesTitle>A note for {props.selectedItemLabel}</NotesTitle>
        <NotesBody>{props.selectedItemBody}</NotesBody>
      </NotesTextCont>
      <NotesFooter>
        <RedoNotes onClick={() => redo()} tabIndex={1}>
          <span>
            <RefreshIcon />
          </span>
          Another One
        </RedoNotes>
      </NotesFooter>
    </NotesViewContainer>
  )
}

export default NotesView

const NotesViewContainer = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  height: 50%;
  @media screen and (max-width: 50rem) {
    flex: 1 1 100%;
    height: 50%;
  }
`
const NotesTextCont = styled.div``
const NotesTitle = styled.h3`
  flex: 0 1 4.15rem;
  font-size: 2.441rem;
  font-family: var(--StyledFont);
  text-transform: capitalize;
  font-weight: bold;
  color: var(--black);
  margin: 0;
`
const NotesBody = styled.p`
  flex: 0 1 auto;
  font-family: var(--StyledFont);
  color: var(--black);
  font-size: 1.953rem;
  line-height: 3.3rem;
  margin: 0 0 calc(var(--standardSpacer) * 4.5) 0;
`
const NotesFooter = styled.div`
  flex: 0 1 max-content;
  display: flex;
  justify-content: center;
`
const RedoNotes = styled.button`
  flex: 0 1 max-content;
  margin-right: auto;
  font-family: var(--SansFont);
  display: flex;
  padding: var(--standardSpacer);
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0px;
  color: var(--black);
  transition: color var(--transitionTime) ease-in-out;

  &:hover,
  &:focus {
    cursor: pointer;
    color: var(--lightGray);
    span > svg {
      fill: var(--lightGray);
    }
  }

  span {
    width: 1.125rem;
    margin-right: var(--standardSpacer);

    svg {
      fill: var(--black);
      transition: fill var(--transitionTime) ease-in-out;
    }
  }
`
