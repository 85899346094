import React from "react"
import styled from "styled-components"
import NoteDropdown from "./noteDropdown"
import { noteTypeList } from "../assets/data/notes"

const SearchView = (props) => {
  const setChoice = (selLabel, selVal) => {
    props.callback(selLabel, selVal)
  }
  const dropdownContent = noteTypeList

  return (
    <SearchViewContainer>
      <Header>
        <MainTitle>Insincere Notes</MainTitle>
        <MainSubTitle>For when you don’t have the words</MainSubTitle>
      </Header>
      <NoteDropdown
        placeholder="What's the occasion?"
        list={dropdownContent}
        callback={setChoice}
      />
    </SearchViewContainer>
  )
}

export default SearchView

const SearchViewContainer = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 90rem) {
    margin-right: 2rem;
  }
  @media screen and (max-width: 50rem) {
    flex: 1 1 100%;
    max-width: none;
    height: max-content;
  }
`
const Header = styled.header``
const MainTitle = styled.h1`
  font-family: var(--StyledFont);
  font-size: 3.815rem;
  font-weight: bold;
  margin: 0rem;
  line-height: 3.8125rem;
`
const MainSubTitle = styled.h2`
  font-family: var(--SansFont);
  font-size: 1rem;
  font-weight: 400;
  margin: 0rem;
`
