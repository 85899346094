import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

import SEO from "../components/seo"

import Layout from "../components/layouts/layout-main"

import SearchView from "../components/search-view"
import NotesView from "../components/notes-view"
import Footer from "../components/footer"

import { theNotes } from "../assets/data/notes"
// Eventually should steal focus when a new note is generated using refs with instructions on how to go back
// https://reactjs.org/docs/accessibility.html

// Note content filter
const theNoteFilter = (arr, idNumb, noteType) => {
  const basedOnId = arr.filter((el) => el.noteId === idNumb)
  const basedOnType = basedOnId.filter((el) => el.noteType === noteType)
  return basedOnType
}
// END Note content filter

const IndexPage = () => {
  const [noteLabel, updateNoteLabel] = useState(null)
  const [noteValue, updateNoteValue] = useState(null)
  const [noteBody, updateNoteBody] = useState(null)
  const [randomNum, updateRandomNum] = useState(null)

  const setNoteState = (clickedItemLabel, clickedItemValue) => {
    updateNoteValue(clickedItemValue)
    updateNoteLabel(clickedItemLabel)
  }

  const makeRandomNumber = (randomNum) => {
    const newRandom = () => {
      var newNumb = Math.floor(Math.random() * 10 + 1)
      while (newNumb === randomNum) {
        newNumb = Math.floor(Math.random() * 10 + 1)
      }
      return newNumb
    }
    updateRandomNum(newRandom)
  }

  // const againButton = useRef()

  const showTheNote = () => {
    makeRandomNumber()
    const theNote = theNoteFilter(theNotes, randomNum, noteValue)
    if (noteValue === null) {
      return
    } else {
      updateNoteBody(theNote[0].noteContent)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    showTheNote(noteValue)
  }, [noteValue])

  return (
    <Layout>
      <SEO title="Insincere Notes" />
      <InnerContentCont>
        <SearchView callback={setNoteState} />
        {noteBody === null ? (
          <></>
        ) : (
          <NotesView
            selectedItemLabel={noteLabel}
            selectedItemBody={noteBody}
            callback={showTheNote}
          />
        )}
      </InnerContentCont>
      <Footer />
    </Layout>
  )
}

export default IndexPage

const InnerContentCont = styled.div`
  flex: 1 1 100%;
  display: flex;
  width: 100%;
  max-width: 90rem;
  align-items: center;

  @media screen and (max-width: 50rem) {
    flex-wrap: wrap;
  }
`
