import React from "react"

export const RefreshIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.39 18.39">
    <path
      id="Path_1"
      data-name="Path 1"
      d="M72.543,745.214a9.227,9.227,0,0,0-6.5,2.7l-2.695-2.7V753.1h7.881L67.9,749.767a6.566,6.566,0,1,1-1.047,7.924l-2.275,1.316a9.2,9.2,0,1,0,7.965-13.793Z"
      transform="translate(-63.348 -745.214)"
    />
  </svg>
)
export const TitleIcon = (props) => <svg></svg>
export const DropdownArrowDownIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 15">
    <g transform="translate(17 15) rotate(180)">
      <path
        d="M 16.1419677734375 14.5 L 0.8580323457717896 14.5 L 8.5 1.014174699783325 L 16.1419677734375 14.5 Z"
        stroke="none"
      />
      <path
        d="M 8.5 2.028339385986328 L 1.716058731079102 14 L 15.2839412689209 14 L 8.5 2.028339385986328 M 8.5 0 L 17 15 L 0 15 L 8.5 0 Z"
        stroke="none"
      />
    </g>
  </svg>
)
export const DropdownArrowUpIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 15">
    <g>
      <path
        d="M 16.1419677734375 14.5 L 0.8580323457717896 14.5 L 8.5 1.014174699783325 L 16.1419677734375 14.5 Z"
        stroke="none"
      />
      <path
        d="M 8.5 2.028339385986328 L 1.716058731079102 14 L 15.2839412689209 14 L 8.5 2.028339385986328 M 8.5 0 L 17 15 L 0 15 L 8.5 0 Z"
        stroke="none"
      />
    </g>
  </svg>
)

// Icons for the various types of notes
