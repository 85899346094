import React from "react"
import styled from "styled-components"

const Footer = () => {
  return (
    <FooterCont>
      <span>
        another dumb project from{" "}
        <a href="https://www.coreyhunt.com" target="_blank" rel="noreferrer">
          corey hunt
        </a>
      </span>
      <span>
        {" "}
        Copyright &#169; {new Date().getFullYear()} Corey Hunt All rights
        reserved
      </span>
    </FooterCont>
  )
}

export default Footer

const FooterCont = styled.footer`
  flex: 0 1rem;
  font-family: var(--SansFont);
  color: var(--gray);
  text-align: center;
  @media screen and (max-width: 50rem) {
    display: flex;
    flex-wrap: wrap;
  }

  span {
    @media screen and (max-width: 50rem) {
      flex: 1 1 100%;
    }
  }

  span:first-child {
    margin-right: 3rem;
    @media screen and (max-width: 50rem) {
      margin-right: 0;
    }
  }
`
