import React, { useRef, useState } from "react"
import styled from "styled-components"
import useOnClickOutside from "./hooks/hook-on-click-outside"

import { DropdownArrowDownIcon, DropdownArrowUpIcon } from "./icons"

const NoteDropdown = (props) => {
  const { placeholder } = props
  const { list } = props
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef()
  // State for our modal
  const [isModalOpen, setModalOpen] = useState(false)
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setModalOpen(false))
  const toggleList = () => {
    setModalOpen(!isModalOpen)
  }

  const setChoice = (selLabel, selVal) => {
    props.callback(selLabel, selVal)
    toggleList()
  }

  return (
    <DDWrapper>
      <DDHeader
        onClick={() => toggleList()}
        onKeyPress={() => toggleList()}
        aria-expanded={isModalOpen}
        tabIndex={0}
        aria-haspopup="listbox"
        aria-labelledby="placeholder-label"
      >
        <DDHeaderTitle role="label" id="placeholder-label">
          {placeholder}
        </DDHeaderTitle>
        {isModalOpen ? <DropdownArrowUpIcon /> : <DropdownArrowDownIcon />}
      </DDHeader>
      {isModalOpen && (
        <DDList ref={ref} role="listbox">
          {list.map((item) => (
            <DDListItem
              key={item.id}
              onClick={() => setChoice(item.label, item.value)}
              onKeyPress={() => setChoice(item.label, item.value)}
              tabIndex={0}
              role="option"
              aria-controls="notes-view"
            >
              {item.label}
            </DDListItem>
          ))}
        </DDList>
      )}
    </DDWrapper>
  )
}

export default NoteDropdown

const DDWrapper = styled.div`
  position: relative;
  width: 50%;
  height: auto;
  color: var(--black);
  font-family: var(--SansFont);
  margin-top: 5rem;

  @media screen and (max-width: 90rem) {
    width: 100%;
  }
  @media screen and (max-width: 50rem) {
    width: 100%;
    margin-top: 2rem;
  }

  &:hover,
  &:focus {
    background-color: var(--grayHover);
    cursor: pointer;
  }
`
const DDHeader = styled.div`
  display: flex;
  border-bottom: 1px solid var(--gray);
  padding: 0.5rem 1rem;
  height: 1.5rem;
  transition: background-color var(--transitionTime) ease-in-out;

  &:hover,
  &:focus {
    background-color: var(--grayHover);
    cursor: pointer;
  }

  svg {
    width: 0.75rem; /* 17px */
    fill: var(--lightGray);
  }
`

const DDHeaderTitle = styled.div`
  color: var(--lightGray);
  font-family: var(--SansFont);
  flex: 1 1 90%;
`

const DDList = styled.ul`
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 0;
  top: 2.5rem;
  list-style: none;
  padding: 0;
  top: 2.3125rem;
  left: 0;
  right: 0;
  border: 1px solid var(--gray);
  z-index: 1;
  background-color: white;
`

const DDListItem = styled.li`
  padding: 0.5rem 1rem;
  font-family: var(--SansFont);
  color: var(--black);
  transition: background-color var(--transitionTime) ease-in-out;
  border-bottom: 1px solid var(--grayHover);

  &:last-child {
    border-bottom: 0px;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: var(--grayHover);
  }
`
