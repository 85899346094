export const noteTypeList = [
  {
    id: 1,
    label: `Birthday`,
    value: `birthday`,
    selected: false,
  },
  {
    id: 2,
    label: `Having a baby`,
    value: `baby`,
    selected: false,
  },
  {
    id: 3,
    label: `Get Well Soon`,
    value: `get_well`,
    selected: false,
  },
  {
    id: 4,
    label: `Going away`,
    value: `going_away`,
    selected: false,
  },
  {
    id: 5,
    label: `Marriage`,
    value: `marriage`,
    selected: false,
  },
  {
    id: 6,
    label: `Passing of a family member`,
    value: `dead_fam`,
    selected: false,
  },
  {
    id: 7,
    label: `New Pet`,
    value: `new_pet`,
    selected: false,
  },
  // {
  //   id: 8,
  //   label: `Passing of a pet`,
  //   value: `dead_pet`,
  //   selected: false,
  // },
  {
    id: 9,
    label: `Welcome back`,
    value: `welcome_back`,
    selected: false,
  },
  {
    id: 10,
    label: `Thank you`,
    value: `thank_you`,
    selected: false,
  },
  {
    id: 11,
    label: `Yearbook`,
    value: `yearbook`,
    selected: false,
  },
]

/* Types
- birthday*
- baby*
- new_pet*
- dead_pet
- dead_fam*
- thank_you*
- going_away*
- welcome_back*
- marriage*
- yearbook*
- get_well*
*/

export const theNotes = [
  {
    noteType: `welcome_back`,
    noteId: 1,
    noteContent: `Welcome back. Things weren't the same without you.`,
  },
  {
    noteType: `welcome_back`,
    noteId: 2,
    noteContent: `Wait, you were gone? J/K Welcome back!`,
  },
  {
    noteType: `welcome_back`,
    noteId: 3,
    noteContent: `I'm so happy to see you back here.`,
  },
  {
    noteType: `welcome_back`,
    noteId: 4,
    noteContent: `Welcome back, [name].`,
  },
  {
    noteType: `welcome_back`,
    noteId: 5,
    noteContent: `Couldn't stay away, huh? Welcome back.`,
  },
  {
    noteType: `welcome_back`,
    noteId: 6,
    noteContent: `We're thrilled to have you back. If you need help settling in, let me know.`,
  },
  {
    noteType: `welcome_back`,
    noteId: 7,
    noteContent: `Joy of joys, you're back!`,
  },
  {
    noteType: `welcome_back`,
    noteId: 8,
    noteContent: `I thought we agreed that you wouldn't come back.`,
  },
  {
    noteType: `welcome_back`,
    noteId: 9,
    noteContent: `You've made my week. Happy to have you back.`,
  },
  {
    noteType: `welcome_back`,
    noteId: 10,
    noteContent: `Nooice! You're back!`,
  },
  {
    noteType: `dead_fam`,
    noteId: 1,
    noteContent: `I'm genuinely sorry for your loss.`,
  },
  {
    noteType: `dead_fam`,
    noteId: 2,
    noteContent: `Please remember that we're all here for you.`,
  },
  {
    noteType: `dead_fam`,
    noteId: 3,
    noteContent: `I'm [thinking/praying] for you and your family during this difficult time.`,
  },
  {
    noteType: `dead_fam`,
    noteId: 4,
    noteContent: `I hope you and your family find peace during this difficult time.`,
  },
  {
    noteType: `dead_fam`,
    noteId: 5,
    noteContent: `I'm here to listen if you find that you'd like to talk about [your grandma, husband, etc.].`,
  },
  {
    noteType: `dead_fam`,
    noteId: 6,
    noteContent: `My heart breaks for you. I'm sending you all of my love.`,
  },
  {
    noteType: `dead_fam`,
    noteId: 7,
    noteContent: `I am at a loss of what to write. My heart goes out to you and your family.`,
  },
  {
    noteType: `dead_fam`,
    noteId: 8,
    noteContent: `I'm sorry that you have to go through this. Take care of yourself.`,
  },
  {
    noteType: `dead_fam`,
    noteId: 9,
    noteContent: `I've got you covered here while you take care of you and yours.`,
  },
  {
    noteType: `dead_fam`,
    noteId: 10,
    noteContent: `Peace and be well.`,
  },
  {
    noteType: `new_pet`,
    noteId: 1,
    noteContent: `Yo dog! Congrats on your new kitten.`,
  },
  {
    noteType: `new_pet`,
    noteId: 2,
    noteContent: `Cat daddy/mama!`,
  },
  {
    noteType: `new_pet`,
    noteId: 3,
    noteContent: `That face tho.`,
  },
  {
    noteType: `new_pet`,
    noteId: 4,
    noteContent: `It's so cute. I want to swallow it whole.`,
  },
  {
    noteType: `new_pet`,
    noteId: 5,
    noteContent: `It might be best to check my pockets/purse when I leave on my next visit.`,
  },
  {
    noteType: `new_pet`,
    noteId: 6,
    noteContent: `pictures! Pictures! PICTURES!`,
  },
  {
    noteType: `new_pet`,
    noteId: 7,
    noteContent: `Aww, it has your ears.`,
  },
  {
    noteType: `new_pet`,
    noteId: 8,
    noteContent: `So cute. Did you pick it or did it choose you?`,
  },
  {
    noteType: `new_pet`,
    noteId: 9,
    noteContent: `I thought your birthday was last year. Happy birthday, anyway.`,
  },
  {
    noteType: `new_pet`,
    noteId: 10,
    noteContent: `That.Is.One.Cute.[animal type]`,
  },
  {
    noteType: `birthday`,
    noteId: 1,
    noteContent: `Happy, happy, happy birthday!`,
  },
  {
    noteType: `birthday`,
    noteId: 2,
    noteContent: `I hope your birthday is a dope birthday!`,
  },
  {
    noteType: `birthday`,
    noteId: 3,
    noteContent: `Warmest wishes for a happy birthday.`,
  },
  {
    noteType: `birthday`,
    noteId: 4,
    noteContent: `Damn, this birthday looks good on you.`,
  },
  {
    noteType: `birthday`,
    noteId: 5,
    noteContent: `Have a great birthday!`,
  },
  {
    noteType: `birthday`,
    noteId: 6,
    noteContent: `You made it! Happy birthday!`,
  },
  {
    noteType: `birthday`,
    noteId: 7,
    noteContent: `I hope you have something delicious today!!`,
  },
  {
    noteType: `birthday`,
    noteId: 8,
    noteContent: `May you have more birthdays than you know what to do with.`,
  },
  {
    noteType: `birthday`,
    noteId: 9,
    noteContent: `I thought your birthday was last year. Happy birthday, anyway.`,
  },
  {
    noteType: `birthday`,
    noteId: 10,
    noteContent: `I hope this year is even more fulfilling than the last. Happy birthday`,
  },
  {
    noteType: `get_well`,
    noteId: 1,
    noteContent: `Hope you get to feeling better soon!`,
  },
  {
    noteType: `get_well`,
    noteId: 2,
    noteContent: `Looking forward to seeing you back here when you're ready.`,
  },
  {
    noteType: `get_well`,
    noteId: 3,
    noteContent: `Warmest wishes for a speedy recovery!`,
  },
  {
    noteType: `get_well`,
    noteId: 4,
    noteContent: `You're in my warmest thoughts as you recover.`,
  },
  {
    noteType: `get_well`,
    noteId: 5,
    noteContent: `Sending some good, healthy energy your way.`,
  },
  {
    noteType: `get_well`,
    noteId: 6,
    noteContent: `We miss you around here. Get better soon.`,
  },
  {
    noteType: `get_well`,
    noteId: 7,
    noteContent: `If you need anything, don't hesitate to call. Get better soon.`,
  },
  {
    noteType: `get_well`,
    noteId: 8,
    noteContent: `Stay strong, [name]; you've got this.`,
  },
  {
    noteType: `get_well`,
    noteId: 9,
    noteContent: `Did you feel that? It's all of our "get better" vibes beaming at you.`,
  },
  {
    noteType: `get_well`,
    noteId: 10,
    noteContent: `Feel better. In the meantime, I've got some [movie, tv, book] picks for you.`,
  },
  {
    noteType: `baby`,
    noteId: 1,
    noteContent: `That's going to be one lucky baby! Congratulations.`,
  },
  {
    noteType: `baby`,
    noteId: 2,
    noteContent: `Congratulations on your little one.`,
  },
  {
    noteType: `baby`,
    noteId: 3,
    noteContent: `Hi baby. Buy low, sell high. Don't spit into the wind.`,
  },
  {
    noteType: `baby`,
    noteId: 4,
    noteContent: `Welcome to the world, little one. We've been expecting you.`,
  },
  {
    noteType: `baby`,
    noteId: 5,
    noteContent: `Happy to have you as part of the human race, baby!`,
  },
  {
    noteType: `baby`,
    noteId: 6,
    noteContent: `Congratulations on the joyous addition to your family.`,
  },
  {
    noteType: `baby`,
    noteId: 7,
    noteContent: `May you have a life filled with happiness and love, little one.`,
  },
  {
    noteType: `baby`,
    noteId: 8,
    noteContent: `Congratulations on your new family member. You've got this! `,
  },
  {
    noteType: `baby`,
    noteId: 9,
    noteContent: `We're so happy for your new addition.`,
  },
  {
    noteType: `baby`,
    noteId: 10,
    noteContent: `Congratulations on the baby. You're going to kill it! I mean..do really great!`,
  },
  {
    noteType: `going_away`,
    noteId: 1,
    noteContent: `Good luck on your new adventure. We'll miss you.`,
  },
  {
    noteType: `going_away`,
    noteId: 2,
    noteContent: `It's been great working with you and getting to know you.`,
  },
  {
    noteType: `going_away`,
    noteId: 3,
    noteContent: `We're sorry to see you go, but congratulations.`,
  },
  {
    noteType: `going_away`,
    noteId: 4,
    noteContent: `Awesome to have met you.`,
  },
  {
    noteType: `going_away`,
    noteId: 5,
    noteContent: `May you kill it on your next venture!`,
  },
  {
    noteType: `going_away`,
    noteId: 6,
    noteContent: `May you find what you're looking for. You'll do great.`,
  },
  {
    noteType: `going_away`,
    noteId: 7,
    noteContent: `Things won't be the same around here. Good luck!`,
  },
  {
    noteType: `going_away`,
    noteId: 8,
    noteContent: `The only good thing about you leaving is the going-away cake—best of luck.`,
  },
  {
    noteType: `going_away`,
    noteId: 9,
    noteContent: `Bon voyage and best of luck!`,
  },
  {
    noteType: `going_away`,
    noteId: 10,
    noteContent: `You'll be missed. You were an asset and a great colleague.`,
  },
  {
    noteType: `yearbook`,
    noteId: 1,
    noteContent: `Stay cool.`,
  },
  {
    noteType: `yearbook`,
    noteId: 2,
    noteContent: `See you next year.`,
  },
  {
    noteType: `yearbook`,
    noteId: 3,
    noteContent: `Don't ever change!`,
  },
  {
    noteType: `yearbook`,
    noteId: 4,
    noteContent: `(Your name) was here.`,
  },
  {
    noteType: `yearbook`,
    noteId: 5,
    noteContent: `If the opportunity doesn't knock, build a door.`,
  },
  {
    noteType: `yearbook`,
    noteId: 6,
    noteContent: `Have a [insert word here] summer.`,
  },
  {
    noteType: `yearbook`,
    noteId: 7,
    noteContent: `You made [class name] actually worth going to.`,
  },
  {
    noteType: `yearbook`,
    noteId: 8,
    noteContent: `We did it!`,
  },
  {
    noteType: `yearbook`,
    noteId: 9,
    noteContent: `We the best. The greatest!`,
  },
  {
    noteType: `yearbook`,
    noteId: 10,
    noteContent: `Cows go moo ducks go quack I'm the first to sign your crack.`,
  },
  {
    noteType: `marriage`,
    noteId: 1,
    noteContent: `Sooo happy for the both of you.`,
  },
  {
    noteType: `marriage`,
    noteId: 2,
    noteContent: `Congratulations on the nuptials!`,
  },
  {
    noteType: `marriage`,
    noteId: 3,
    noteContent: `You're going to have a great time. You heard it here first!`,
  },
  {
    noteType: `marriage`,
    noteId: 4,
    noteContent: `May you have love today and always.`,
  },
  {
    noteType: `marriage`,
    noteId: 5,
    noteContent: `I hope you have a damn fine time.`,
  },
  {
    noteType: `marriage`,
    noteId: 6,
    noteContent: `Poof! You + [him/her/name] = MAGIC!`,
  },
  {
    noteType: `marriage`,
    noteId: 7,
    noteContent: `Like pizza and beer, hummus and pitas, or drunk and Taco Bell, you two are a perfect combination.`,
  },
  {
    noteType: `marriage`,
    noteId: 8,
    noteContent: `Wishing you well as you begin this next chapter of your life together. Cheers!`,
  },
  {
    noteType: `marriage`,
    noteId: 9,
    noteContent: `Here's to an existence full of happiness and love.`,
  },
  {
    noteType: `marriage`,
    noteId: 10,
    noteContent: `You two make one great team! Mazel tov.`,
  },
  {
    noteType: `going_away`,
    noteId: 1,
    noteContent: `Good luck on your new adventure. We'll miss you.`,
  },
  {
    noteType: `going_away`,
    noteId: 2,
    noteContent: `It's been great working with you and getting to know you.`,
  },
  {
    noteType: `going_away`,
    noteId: 3,
    noteContent: `We're sorry to see you go, but congratulations.`,
  },
  {
    noteType: `going_away`,
    noteId: 4,
    noteContent: `Awesome to have met you.`,
  },
  {
    noteType: `going_away`,
    noteId: 5,
    noteContent: `May you kill it on your next venture!`,
  },
  {
    noteType: `going_away`,
    noteId: 6,
    noteContent: `May you find what you're looking for. You'll do great.`,
  },
  {
    noteType: `going_away`,
    noteId: 7,
    noteContent: `Things won't be the same around here. Good luck!`,
  },
  {
    noteType: `going_away`,
    noteId: 8,
    noteContent: `The only good thing about you leaving is the going-away cake—best of luck.`,
  },
  {
    noteType: `going_away`,
    noteId: 9,
    noteContent: `Bon voyage and best of luck!`,
  },
  {
    noteType: `going_away`,
    noteId: 10,
    noteContent: `You'll be missed. You were an asset and a great colleague.`,
  },
  {
    noteType: `thank_you`,
    noteId: 1,
    noteContent: `You don't need a note generator to write a thank you card.
                  Thank the gift giver for the thing/action and tell them how much it meant to you.`,
  },
  {
    noteType: `thank_you`,
    noteId: 2,
    noteContent: `You don't need a note generator to write a thank you card.
                  Thank the gift giver for the thing/action and tell them how much it meant to you.`,
  },
  {
    noteType: `thank_you`,
    noteId: 3,
    noteContent: `You don't need a note generator to write a thank you card.
                  Thank the gift giver for the thing/action and tell them how much it meant to you.`,
  },
  {
    noteType: `thank_you`,
    noteId: 4,
    noteContent: `You don't need a note generator to write a thank you card.
                  Thank the gift giver for the thing/action and tell them how much it meant to you.`,
  },
  {
    noteType: `thank_you`,
    noteId: 5,
    noteContent: `You don't need a note generator to write a thank you card.
                  Thank the gift giver for the thing/action and tell them how much it meant to you.`,
  },
  {
    noteType: `thank_you`,
    noteId: 6,
    noteContent: `You don't need a note generator to write a thank you card.
                  Thank the gift giver for the thing/action and tell them how much it meant to you.`,
  },
  {
    noteType: `thank_you`,
    noteId: 7,
    noteContent: `You don't need a note generator to write a thank you card.
                  Thank the gift giver for the thing/action and tell them how much it meant to you.`,
  },
  {
    noteType: `thank_you`,
    noteId: 8,
    noteContent: `You don't need a note generator to write a thank you card.
                  Thank the gift giver for the thing/action and tell them how much it meant to you.`,
  },
  {
    noteType: `thank_you`,
    noteId: 9,
    noteContent: `You don't need a note generator to write a thank you card.
                  Thank the gift giver for the thing/action and tell them how much it meant to you.`,
  },
  {
    noteType: `thank_you`,
    noteId: 10,
    noteContent: `You don't need a note generator to write a thank you card.
                  Thank the gift giver for the thing/action and tell them how much it meant to you.`,
  },
]
